import React from 'react';

// STYLING
import './Header.css';

const Header = () => {
	return (
		<div className="headerItem">
			<div className="header">Sir David Attenbowwow</div>
		</div>
	);
};

export default Header;
